var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-app",
    { attrs: { id: "app" } },
    [
      _setup.session.app_mode === "donation"
        ? _c(_setup.PaymentMask, { attrs: { iframe: "" } })
        : _vm._e(),
      _setup.session.app_mode !== "donation" ? _c(_setup.DebugBar) : _vm._e(),
      _setup.session.app_mode !== "donation"
        ? _c(_setup.PaymentBanner)
        : _vm._e(),
      _setup.session.state === "initializing" &&
      _setup.session.app_mode !== "donation"
        ? _c(
            "v-overlay",
            { attrs: { color: "white" } },
            [
              _c("v-progress-circular", {
                attrs: { indeterminate: "", color: "primary" },
              }),
            ],
            1
          )
        : _vm._e(),
      _setup.session.state !== "initializing" &&
      _vm.$route.meta.public === true &&
      _setup.session.app_mode !== "donation"
        ? _c("router-view")
        : _setup.session.state !== "initializing" &&
          _vm.$route.meta.public !== true &&
          _setup.session.app_mode !== "donation"
        ? _c(_setup.BaseApp)
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }