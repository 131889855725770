var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _vm.show_selector
      ? _c("div", [
          _vm.show_ind_selector
            ? _c(
                "div",
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "pt-10 pb-5 pl-10 pr-10 mb-5",
                      attrs: { flat: "" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "text-h6 text-sm-h4 text-center" },
                        [
                          _vm._v(" Mit "),
                          _c("span", { staticClass: "font-weight-bold" }, [
                            _vm._v(_vm._s(_vm.ind_value) + " EUR"),
                          ]),
                          _vm.recurring
                            ? _c("span", [_vm._v(" / Jahr")])
                            : _vm._e(),
                          _vm._v(" unterstützen "),
                        ]
                      ),
                      _c("v-slider", {
                        attrs: {
                          min: _vm.ind_min,
                          max: _vm.ind_max,
                          step: "5",
                        },
                        model: {
                          value: _vm.ind_value,
                          callback: function ($$v) {
                            _vm.ind_value = $$v
                          },
                          expression: "ind_value",
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "text-center" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "primary",
                              attrs: { depressed: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.onClickAmountButton(_vm.ind_value)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "Jetzt mit " + _vm._s(_vm.ind_value) + " EUR"
                              ),
                              _vm.recurring
                                ? _c("span", [_vm._v(" / Jahr")])
                                : _vm._e(),
                              _vm._v(" unterstützen"),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c("div", [
                _c("div", { staticClass: "donation-holder" }, [
                  _c(
                    "div",
                    {
                      staticClass: "donation-tier",
                      on: {
                        click: function ($event) {
                          return _vm.onClickAmountButton(9)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "donation-text" }, [
                        _c("span", { staticClass: "line" }, [_vm._v("Mit")]),
                        _c("span", { staticClass: "line" }, [
                          _vm._v("9 EUR "),
                          _vm.recurring
                            ? _c("span", [_vm._v(" / Jahr")])
                            : _vm._e(),
                        ]),
                        _c("span", { staticClass: "line" }, [
                          _vm._v("unterstützen"),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "donation-tier",
                      on: {
                        click: function ($event) {
                          return _vm.onClickAmountButton(15)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "donation-text" }, [
                        _c("span", { staticClass: "line" }, [_vm._v("Mit")]),
                        _c("span", { staticClass: "line" }, [
                          _vm._v("15 EUR"),
                          _vm.recurring
                            ? _c("span", [_vm._v(" / Jahr")])
                            : _vm._e(),
                        ]),
                        _c("span", { staticClass: "line" }, [
                          _vm._v("unterstützen"),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "donation-tier",
                      on: {
                        click: function ($event) {
                          return _vm.onClickIndButton(25)
                        },
                      },
                    },
                    [_vm._m(0)]
                  ),
                ]),
              ]),
          !_vm.iframe
            ? _c("div", [
                _setup.session.is_authenticated
                  ? _c("div", { staticClass: "d-flex justify-center mt-5" }, [
                      _vm.recurring
                        ? _c(
                            "div",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    depressed: "",
                                    disabled: !_setup.session.is_authenticated,
                                    color: "primary",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.recurring = !_vm.recurring
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", { attrs: { left: "" } }, [
                                    _vm._v("mdi-check-decagram"),
                                  ]),
                                  _vm._v("Jährlich unterstützen "),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _c(
                            "div",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    depressed: "",
                                    disabled: !_setup.session.is_authenticated,
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.recurring = !_vm.recurring
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { left: "", small: "" } },
                                    [_vm._v("mdi-check-decagram")]
                                  ),
                                  _vm._v("Jährlich unterstützen "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass: "text-center",
                    staticStyle: {
                      "padding-top": "10px",
                      "padding-bottom": "10px",
                      "font-size": "12px",
                    },
                  },
                  [_vm._v("oder")]
                ),
                _c(
                  "div",
                  { staticClass: "d-flex justify-center pb-5" },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: { depressed: "" },
                        on: {
                          click: function ($event) {
                            return _vm.dismissPaymentRequest()
                          },
                        },
                      },
                      [_vm._v("Ohne Unterstützung weiter")]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
    _vm.show_email
      ? _c("div", [
          _c(
            "div",
            { staticClass: "text-center ma-3" },
            [
              _c("h1", { staticClass: "text-h6 font-weight-bold mt-5 mb-1" }, [
                _vm._v("Bitte geben Sie Ihre E-Mail Adresse ein"),
              ]),
              _c("v-card-text", [
                _vm._v(
                  "Mit Hilfe Ihrer E-Mail-Adresse können wir Ihnen die Bestätigung Ihrer Unterstützung zusenden."
                ),
              ]),
              _c(
                "div",
                { staticStyle: { "max-width": "400px", margin: "0 auto" } },
                [
                  _c(
                    "v-form",
                    {
                      ref: "form",
                      model: {
                        value: _vm.form_valid,
                        callback: function ($$v) {
                          _vm.form_valid = $$v
                        },
                        expression: "form_valid",
                      },
                    },
                    [
                      _c("v-text-field", {
                        staticClass: "mx-3",
                        attrs: {
                          outlined: "",
                          label: "Ihre E-Mail Adresse",
                          type: "email",
                          rules: _vm.emailRules,
                          required: "",
                        },
                        model: {
                          value: _vm.email,
                          callback: function ($$v) {
                            _vm.email = $$v
                          },
                          expression: "email",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "text-center mx-3" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "primary mb-5",
                          attrs: { disabled: !_vm.form_valid, depressed: "" },
                          on: {
                            click: function ($event) {
                              return _vm.onClickAmountButton(_vm.ind_value)
                            },
                          },
                        },
                        [
                          _vm._v(
                            " Betrag von " + _vm._s(_vm.ind_value) + " EUR"
                          ),
                          _vm.recurring
                            ? _c("span", [_vm._v(" / Jahr")])
                            : _vm._e(),
                          _vm._v(" bestätigen "),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "donation-text" }, [
      _c("span", { staticClass: "line" }, [_vm._v("Betrag")]),
      _c("span", { staticClass: "line" }, [_vm._v("selbst")]),
      _c("span", { staticClass: "line" }, [_vm._v("festlegen")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }