var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-main",
        [
          _c(
            "v-container",
            [
              _c(
                "v-card",
                { staticClass: "mb-5", attrs: { flat: "", outlined: "" } },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { flat: "" } },
                    [
                      _c("v-img", {
                        staticClass: "mx-2",
                        attrs: {
                          src: require("@/assets/robinsonliste-logo.png"),
                          "max-height": "50",
                          "max-width": "200",
                          contain: "",
                        },
                      }),
                      _c("v-spacer"),
                      _setup.session.getOfferDonation
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "hidden-xs-only",
                              staticStyle: { color: "#ffffff" },
                              attrs: { color: "orange", depressed: "" },
                              on: {
                                click: function ($event) {
                                  _setup.session.show_payment_banner = true
                                },
                              },
                            },
                            [
                              _c("v-icon", { attrs: { left: "" } }, [
                                _vm._v("mdi-hand-coin"),
                              ]),
                              _vm._v("JETZT unterstützen!"),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ml-1",
                          attrs: { depressed: "" },
                          on: {
                            click: function ($event) {
                              return _setup.session.logout(true)
                            },
                          },
                        },
                        [
                          _vm._v("Abmelden "),
                          _c("v-icon", { attrs: { right: "" } }, [
                            _vm._v("mdi-logout"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("router-view"),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }