var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { modal: "", "max-width": "1000", persistent: "" },
          model: {
            value: _setup.session.show_payment_banner,
            callback: function ($$v) {
              _vm.$set(_setup.session, "show_payment_banner", $$v)
            },
            expression: "session.show_payment_banner",
          },
        },
        [
          _c("v-card", { attrs: { flat: "" } }, [
            _c("div", [
              _setup.session.initiating_payment
                ? _c(
                    "div",
                    { staticClass: "d-flex justify-center" },
                    [
                      _c("v-progress-circular", {
                        staticClass: "ma-10",
                        attrs: {
                          indeterminate: "",
                          size: "100",
                          color: "primary",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              !_setup.session.initiating_payment
                ? _c(
                    "div",
                    { staticClass: "pa-5" },
                    [
                      _c("div", { staticClass: "text-center" }, [
                        _c(
                          "h3",
                          {
                            staticClass: "text-h4 font-weight-bold",
                            staticStyle: { color: "#34a9e1" },
                          },
                          [_vm._v("Wir brauchen Ihre Unterstützung!")]
                        ),
                        _c("h4", { staticClass: "text-h5 font-weight-bold" }, [
                          _vm._v("Auch kleine Beträge helfen."),
                        ]),
                        _c("p", { staticClass: "mt-3" }, [
                          _vm._v(
                            " Wir kümmern uns um den Schutz Ihrer persönlichen Daten und vermeiden ungewollte Werbung für Sie!"
                          ),
                          _c("br"),
                          _vm._v(
                            " Wir sind für unsere gemeinnützige Tätigkeit aber auf Spenden angewiesen! "
                          ),
                        ]),
                      ]),
                      _c(_setup.PaymentMask),
                      _c("div", { staticClass: "text-center" }, [
                        _c("p", { staticClass: "font-weight-bold mb-1" }, [
                          _vm._v(
                            "Seit über 25 Jahren die Nr. 1 im Verbraucherschutz gegen Werbung"
                          ),
                          _c("br"),
                        ]),
                        _c(
                          "p",
                          {
                            staticStyle: {
                              color: "#333",
                              "font-size": "10px",
                              "max-width": "650px",
                              margin: "0 auto",
                            },
                          },
                          [
                            _vm._v(
                              " Die Deutschen Robinsonlisten sind mit über 4 Mio. Nutzern die größte unabhängige Verbraucherschutzhilfe in Deutschland. Der Eintrag in die Deutsche Robinsonliste ist IMMER kostenlos! Helfen Sie, auch Ihre Familie zu schützen und tragen Sie direkt Ihre Eltern, Großeltern und Kinder in unsere Schutzlisten ein. "
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }