var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _vm.environment === "development"
      ? _c(
          "div",
          { staticClass: "pa-3" },
          [
            _c("v-chip", { staticClass: "ma-3", attrs: { label: "" } }, [
              _vm._v("Environment: " + _vm._s(_vm.environment)),
            ]),
            _c("v-chip", { staticClass: "ma-3", attrs: { label: "" } }, [
              _vm._v("Session State: " + _vm._s(_setup.session.state)),
            ]),
            _c("v-chip", { staticClass: "ma-3", attrs: { label: "" } }, [
              _vm._v("Route is public: " + _vm._s(_vm.$route.meta.public)),
            ]),
            _c("v-chip", { staticClass: "ma-3", attrs: { label: "" } }, [
              _vm._v("Route name: " + _vm._s(_vm.$route.name)),
            ]),
            _c("v-chip", { staticClass: "ma-3", attrs: { label: "" } }, [
              _vm._v(
                "Authenticated: " + _vm._s(_setup.session.is_authenticated)
              ),
            ]),
            _c("v-chip", { staticClass: "ma-3", attrs: { label: "" } }, [
              _vm._v("Admin: " + _vm._s(_setup.session.is_admin)),
            ]),
            _c(
              "v-chip",
              { staticClass: "ma-3", attrs: { color: "primary", label: "" } },
              [
                _vm._v(
                  "Hat bereits gespendet: " +
                    _vm._s(!_setup.session.show_payment_banner)
                ),
              ]
            ),
            _c(
              "v-btn",
              {
                attrs: { depressed: "" },
                on: {
                  click: function ($event) {
                    _setup.session.show_payment_banner = true
                  },
                },
              },
              [_vm._v("Zahlungs Popup")]
            ),
            _c(
              "v-btn",
              {
                staticClass: "ml-3",
                attrs: { depressed: "" },
                on: {
                  click: function ($event) {
                    return _vm.loginAdmin()
                  },
                },
              },
              [_vm._v("Dev: Admin Login")]
            ),
            _c(
              "v-btn",
              {
                staticClass: "ml-3",
                attrs: { depressed: "" },
                on: {
                  click: function ($event) {
                    return _vm.loginUser()
                  },
                },
              },
              [_vm._v("Dev: User Login")]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }